<template>
  <div class="body">
    <section>
      <div class="doctor-item">
        <div class="doctor-img">
          <img
            src="@/assets/img/yisheng.png"
            v-if="doctor.portraitUrl == null || doctor.portraitUrl == ''"
            class="doctor-img"
            alt=""
          />
          <img :src="doctor.portraitUrl" v-else class="doctor-img" alt="" />
        </div>
        <div class="doctor-info">
          <div class="doctor-base-info">
            <span class="doctor-name">{{ doctor.doctorName }}</span>
            <span class="doctor-deptName">{{ doctor.doctorTitle }}</span>
            <span class="collect"> 收藏 </span>
            <i
              class="iconfont icon-xinaixin"
              :class="doctor.concern == false ? 'disactive' : 'active'"
              @click="toCollect(doctor.concern)"
            ></i>
            <div class="doctor-dept">{{ doctor.deptName }}</div>
          </div>
          <!-- <div class="doctor-tel">
            <div class="fl">
              <img src="@/assets/img/guide/dingwei.png" alt="" class="icon" />
              <span>{{ doctor.deptPlace == null ? '暂无':doctor.deptPlace }}</span>
            </div>
            <div class="fr">
              <img src="@/assets/img/guide/rili.png" alt="" class="icon" />
              <span style="padding-right: 20px">周三、周六全天</span>
            </div>
            <div class="fl">
              <img src="@/assets/img/guide/phone.png" alt="" class="icon" />
              <span>0371-85901690</span>
            </div>
          </div> -->
        </div>
      </div>

      <div
        class="doctor-cards"
        v-for="(item, index) in doctorList"
        :key="index"
      >
        <div class="doctor-card d-flex-column">
          <div class="order-view">
            <van-cell-group>
              <div
                v-for="(v, i) in item.dates"
                :key="i"
                :index="i"
                @click="selectDoctor(v, item)"
              >
                <van-cell
                  :title="v.title"
                  v-if="v.StopFlag == 0"
                  is-link
                  :value="'余' + v.SurplusCount"
                  value-class="order-title"
                  style="text-align: left"
                />
                <van-cell v-else title="暂未开放" />
              </div>
            </van-cell-group>
          </div>
        </div>
      </div>
      <div style="margin-top: 25px">
        <div class="good-info">
          <div class="good-title">
            <img src="@/assets/img/guide/good.png" alt="" class="good-icon" />
            擅长
          </div>
          <div class="good-content">
            {{ doctor.shanChang == null ? "暂无" : doctor.shanChang }}
          </div>
        </div>
        <div class="line"></div>
        <div class="good-info">
          <div class="good-title">
            <img
              src="@/assets/img/guide/profile.png"
              alt=""
              class="good-icon"
            />
            简介
          </div>
          <div class="good-content">
            {{ doctor.jianJie == null ? "暂无简介" : doctor.jianJie }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Notify } from "vant";
import moment from "moment"; // 引入moment
import _ from "lodash";
export default {
  name: "doctorPage",
  data() {
    return {
      doctor: {},
      dateEnum: {
        Mon: "周一",
        Tue: "周二",
        Wed: "周三",
        Thu: "周四",
        Fri: "周五",
        Sat: "周六",
        Sun: "周日",
      },
      isEmpty: true,
      doctorList: [],
    };
  },
  methods: {
    toCollect(flag) {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        doctorId: this.doctor.doctorCode,
        deptCode: this.doctor.deptCode,
      };
      if (!flag) {
        // 收藏
        this.$http.post("/api/favorite/add", postData);
        Notify({
          message: "已收藏",
          color: "#fff",
          background: "#00D48B",
        });
      } else {
        // 取消收藏
        this.$http.put("/api/favorite/cancle", postData);
        Notify({
          message: "取消收藏",
          color: "#fff",
          background: "#C6C6C6",
        });
      }
      this.doctor.concern = !flag;
      sessionStorage.setItem("currentDoctor", JSON.stringify(this.doctor));
    },
    getList() {
      let postData = {
        doctorCode: this.doctor.doctorCode,
        departCode: this.doctor.departCode,
        beginDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).add(2, "days").format("YYYY-MM-DD"),
      };
      this.$http
        .get("/api/appointment/querySchinfoyy", postData)
        .then((res) => {
          if (res.status === 200) {
            this.allData = res.data;
            let doctorGroup = _.groupBy(this.allData, "DoctorCode");
            let totalCount = 0;
            this.doctorList = [];
            for (let key in doctorGroup) {
              let item = doctorGroup[key][0];
              let doctorDateGroup = _.groupBy(doctorGroup[key], "ClinicDate");
              item["dates"] = [];
              item["hasMore"] =
                Object.getOwnPropertyNames(doctorDateGroup).length >= 3;
              for (let ddate in doctorDateGroup) {
                let title =
                  moment(new Date(ddate)).format("MM-DD") +
                  "(" +
                  this.dateEnum[moment(new Date(ddate)).format("ddd")] +
                  ")" +
                  doctorDateGroup[ddate][0].TimeDesc;
                let surplusCount = _.sumBy(
                  doctorDateGroup[ddate],
                  function (o) {
                    return parseInt(o.SurplusCount);
                  }
                );
                item.dates.push({
                  ClinicDate: ddate,
                  title: title,
                  StopFlag: doctorDateGroup[ddate][0].StopFlag,
                  SurplusCount: surplusCount,
                });
                totalCount += _.sumBy(doctorDateGroup[ddate], function (o) {
                  return o.TotalCount - o.SurplusCount;
                });
              }
              item.TotalCount = totalCount;
              this.doctorList.push(item);
              console.log(this.doctorList);
            }
            this.isEmpty = this.doctorList.length == 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectDoctor(time, doctor) {
      // 要缓存医生当天所有预约时间段
      if (!doctor) {
        doctor = this.currentDoctor;
      }
      let doctorDate = _.filter(this.allData, function (o) {
        return (
          o.DoctorCode == doctor.DoctorCode && o.ClinicDate == time.ClinicDate
        );
      });
      window.sessionStorage.setItem(
        "appoint-schedules",
        JSON.stringify(doctorDate)
      );
      this.clinicDateText =
        time.ClinicDate +
        "(" +
        this.dateEnum[moment(new Date(time.ClinicDate)).format("ddd")] +
        ")" +
        doctor.TimeDesc;

      this.$router.push({
        path: "/appointSelect",
        query: {
          doctor: encodeURIComponent(JSON.stringify(doctor)),
          LabelType: this.LabelType,
          clinicDateText: this.clinicDateText,
        },
      });
    },
  },
  mounted() {
    this.doctor = JSON.parse(sessionStorage.getItem("currentDoctor"));
    this.getList();
  },
};
</script>

<style scoped>
@import url(//at.alicdn.com/t/font_2783444_ka1zwfgai2j.css);
.body {
  height: 2rem;
  background-image: linear-gradient(#57fdc4, #00d48b);
}
.icon {
  width: 1em !important;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
}
.doctor-item {
  width: 9rem;
  height: 2.6rem;
  background-color: #fff;
  margin: auto;
  border-radius: 15px;
  position: relative;
  top: 0.4rem;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}
.doctor-img {
  width: 2rem;
  float: left;
}
.doctor-img img {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 5px;
  margin-top: 10px;
  border-radius: 50%;
}
.doctor-info {
  width: 7rem;
  height: inherit;
  float: left;
}
.doctor-base-info {
  width: 7rem;
  height: 1.4rem;
  text-align: left;
  border-bottom: 1px solid #e6e6e6;
  font-size: 0.4rem;
  margin-top: 20px;
}
.doctor-dept {
  text-align: left;
  margin-top: 0.1rem;
}
.doctor-name {
  color: #333333;
  padding-right: 15px;
  font-weight: bold;
}
.doctor-deptName {
  color: #333333;
}
.collect {
  float: right;
  font-size: 0.4rem;
  padding-right: 15px;
  color: #a4a5ab;
}
.icon-xinaixin {
  float: right;
  font-size: 0.45rem !important;
  padding-top: 2px;
  padding-right: 3px;
}
.active {
  color: #00d48b !important;
}
.disactive {
  color: #c6c6c6 !important;
}
.doctor-tel {
  font-size: 0.4rem;
  text-align: left;
}
.doctor-tel div {
  margin-top: 0.1rem;
}
.icon {
  width: 0.35rem;
  height: 0.35rem;
  padding-right: 3px;
}
.good-icon {
  width: 0.4rem;
  height: 0.4rem;
  padding-right: 3px;
  position: relative;
  top: 2px;
}
.good-info {
  width: 9rem;
  margin: 0.2rem auto;
}
.good-title {
  font-size: 0.42rem;
  text-align: left;
}
.good-content {
  margin-top: 0.2rem;
  font-size: 0.4rem;
  text-align: left;
  line-height: 0.7rem;
  text-indent: 0.7rem;
}
.line {
  width: 9rem;
  height: 1px;
  background-color: rgba(212, 212, 212, 1);
  margin: 0.1rem auto;
}
/* 医生列表 */
.doctor-cards {
  width: 9rem;
  padding: 2px 0px 2px 0px;
  margin: 0 auto;
  margin-top: 20px;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  border-radius: 15px;
  overflow: hidden;
}
</style>
